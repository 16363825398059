import { Link } from '@remix-run/react';
import logoSVG from '/static/images/logo-URL.svg?url';
import quizzlyScenes from '/static/images/quizzly-scenes.webp?url';
import quizzlyBrandScenes from '/static/images/brands/quizzly-brand-scenes.webp?url';

const Header = ({ setModalIsOpen, brands }) => {
  return (
    <header id="header">
      <div className="bg-gradient-dark container-center">
        <div className="main-container navbar p-0">
          <div className="d-flex align-items-baseline mt-40">
            <Link to="/" className="navbar-brand navbar-brand-header" reloadDocument={true}>
              <img src={logoSVG} alt="Quizzes to Test Your Knowledge by Quizzly.ai" width={160} height={59} />
              &#8482;
            </Link>
            <ul className="navbar d-sl-none">
              <li>
                <a href="https://dash.vuukle.com/register" className="navbar-links">
                  Publishers
                </a>
              </li>
              <li>
                <Link to="/brands" className="navbar-links">
                  Brands
                </Link>
              </li>
              <li>
                <Link to="/explore" className="navbar-links">
                  Explore
                </Link>
              </li>
            </ul>
          </div>
          <button className="top-button mt-40" onClick={() => setModalIsOpen(true)}>
            Contact Us
          </button>
        </div>
        {!brands ? (
          <div className="main-container d-flex align-items-center mt-90 justify-content-between">
            <div className="d-flex align-items-center justify-content-between top-responsive-box">
              <div>
                <h1 className="welcome-text">
                  WELCOME TO QUIZZLY<span className="gradient-txt-dark">.AI</span>
                </h1>
                <p className="short-info">
                  Elevate Your Website&apos;s Success with AI-Powered Quizzes Redefining Engagement, Loyalty, and Data
                  for Publishers!
                </p>
                <a href="https://vuukle.com/" className="gradient-button">
                  Learn More
                </a>
              </div>
              <img src={quizzlyScenes} alt="quizzly-scenes" className="quizzly-scenes" width={705} />
            </div>
          </div>
        ) : (
          <div className="main-container d-flex align-items-center mt-90 justify-content-center">
            <div className="d-flex align-items-center justify-content-center flex-column top-responsive-box">
              <div className="brand-header">
                <h1 className="welcome-text">
                  Unlock the <span className="gradient-txt-dark">Power</span> of Brand Lift Insights
                </h1>
                <p className="short-info short-info-brand">
                  Gaining a comprehensive understanding of your brand&apos;s impact is crucial for informed
                  decision-making. The Quizzly AI quiz widget enables you to conduct brand lift surveys effortlessly
                </p>
              </div>
              <img src={quizzlyBrandScenes} alt="quizzly-brand-scenes" className="quizzly-brand-scenes" width={1505} />
            </div>
          </div>
        )}
        <div className="arrow-down">
          <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="22.5" cy="22.5" r="21.5" stroke="white" strokeWidth="2" />
            <path
              d="M13.5674 23.8801C13.698 23.749 13.8533 23.645 14.0242 23.574C14.1951 23.503 14.3784 23.4665 14.5635 23.4665C14.7486 23.4665 14.9318 23.503 15.1028 23.574C15.2737 23.645 15.4289 23.749 15.5596 23.8801L21.5936 29.9141L21.5936 12.6875C21.5936 12.3146 21.7417 11.9569 22.0054 11.6931C22.2692 11.4294 22.6268 11.2813 22.9998 11.2813C23.3728 11.2813 23.7305 11.4294 23.9942 11.6931C24.2579 11.9569 24.4061 12.3146 24.4061 12.6875L24.4061 29.9141L30.4424 23.8801C30.7066 23.6159 31.0649 23.4675 31.4385 23.4675C31.8121 23.4675 32.1704 23.6159 32.4346 23.8801C32.6988 24.1443 32.8472 24.5026 32.8472 24.8762C32.8472 25.2498 32.6988 25.6081 32.4346 25.8723L23.9971 34.3098C23.8664 34.4409 23.7112 34.5449 23.5403 34.6159C23.3693 34.6869 23.1861 34.7234 23.001 34.7234C22.8159 34.7234 22.6326 34.6869 22.4617 34.6159C22.2908 34.5449 22.1355 34.4409 22.0049 34.3098L13.5674 25.8723C13.4363 25.7416 13.3323 25.5864 13.2613 25.4155C13.1903 25.2445 13.1538 25.0613 13.1538 24.8762C13.1538 24.6911 13.1903 24.5078 13.2613 24.3369C13.3323 24.166 13.4363 24.0107 13.5674 23.8801Z"
              fill="white"
            />
          </svg>
        </div>
      </div>
    </header>
  );
};

export default Header;
